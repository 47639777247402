import { domain } from './var'

const site = {

    getSystemConfigList        : `${domain}/crm/site/management/getSystemConfigList`, // 获取施工计划列表
    confirmSiteNode            : `${domain}/crm/site/management/confirmSiteNode`, // 确认开工|确认完工--post
    getAcceptanceRecordList    : `${domain}/crm/site/management/getAcceptanceRecordList`, // 获取验收列表-get
    getAcceptanceNodeList      : `${domain}/crm/site/management/getAcceptanceNodeList`, // 获取所有验收工序列表-申请时下拉使用-get
    getExtraBaseList           : `${domain}/crm/site/management/getExtraBaseList`, // 获取个性化基装列表-get
    getConfigDetail            : `${domain}/crm/site/management/getConfigDetail`, // 获取验收详情-get
    applyAcceptanceNode        : `${domain}/crm/site/management/applyAcceptanceNode`, // 申请验收-post
    checkAcceptanceNode        : `${domain}/crm/site/management/checkAcceptanceNode`, // 节点验收 -post
    getUserRewardPunishmentList: `${domain}/crm/site/management/getUserRewardPunishmentList`, // 获取奖罚单列表 -get
    getUserRectifyList         : `${domain}/crm/site/management/getUserRectifyList`, // 获取整改单列表 -get
    getWorkersList             : `${domain}/crm/site/management/getWorkersList`, // 工人列表 -get
    getBaseList                : `${domain}/crm/site/management/getBaseList`, // 施工明细 -get
    assignWorkers              : `${domain}/crm/site/management/assignWorkers`, // 指派工人 -get
    addSitePatrol              : `${domain}/crm/site/management/addSitePatrol`, // 添加巡检
    getPatrolList              : `${domain}/crm/site/management/getPatrolList`, // 巡检列表
    getSiteAllUserService      : `${domain}/crm/site/management/getSiteAllUserService`, //  -get
    getRequestPaymentTitles    : `${domain}/crm/site/management/getRequestPaymentTitles`, //  -get 请款项设置
    getRequestPaymentAmount    : `${domain}/crm/site/management/getRequestPaymentAmount`, //  -get 获取请款金额
    requestPayment             : `${domain}/crm/site/management/requestPayment`, //  -get 请款
    getRequestPaymentList      : `${domain}/crm/site/management/getRequestPaymentList`, //  -get 请款记录
    changeRequestPaymentStatus : `${domain}/crm/site/management/changeRequestPaymentStatus`, //  -get 作废
    getReportProgress          : `${domain}/crm/site/getReportProgress`, //
    selfWorkers                : `${domain}/crm/site/management/selfWorkers`, //  工人列表
    selfLeader                 : `${domain}/crm/site/management/selfLeader`, //  工长列表
    selfCompany                : `${domain}/crm/site/management/selfCompany`, //  公司列表
    getMissionList             : `${domain}/crm/site/management/getMissionList`, //
    bindCompany                : `${domain}/crm/site/management/bindCompany`, //  绑定公司
    bindForeman                : `${domain}/crm/site/management/bindForeman`, //  绑定工厂
    unbindForeman              : `${domain}/crm/site/management/unbindForeman`, //  解绑工长
    getSiteCamera              : `${domain}/crm/site/management/getSiteCamera`, //  -get摄像头
    bindSiteCamera             : `${domain}/crm/site/management/bindSiteCamera`, // post 绑定摄像头
    saveUserRectifyStatus      : `${domain}/crm/site/management/saveUserRectifyStatus`, // 整改单提交验收 -get

    getCompanyName: `${domain}/crm/site/management/getCompanyName`, // 获取公司名称

    getSettlementList: `${domain}/crm/site/management/getSettlementList`, // 结算
    getBillInfo      : `${domain}/crm/site/management/getBillInfo`, // 结算查看详情

    getH5PatrolConfig    : `${domain}/crm/site/management/getH5PatrolConfig`, // 获取巡检配置
    getSitePatrolDetail  : `${domain}/crm/site/management/getSitePatrolDetail`, // 获取巡检详情
    checkSettlementStatus: `${domain}/crm/site/management/checkSettlementStatus`, // -post --工长审核结算

    getFormerBillList           : `${domain}/crm/site/getFormerBillList`, // 获取工长下的结算账单
    getSiteSettlementInfo       : `${domain}/crm/site/management/getSiteSettlementInfo`, // 获取结算单详情
    changeFormerSettlementStatus: `${domain}/crm/site/management/changeFormerSettlementStatus`, // 工长审核工地结算

    getOrderCategoryList: `${domain}/crm/site/getOrderCategoryList`, // 获取材料分类
    batchOrderPlacement : `${domain}/crm/site/batchOrderPlacement`, //  -get h5批量申请下单
    getContractDetail   : `${domain}/crm/site/getContractDetail`, //  -get获取合同明细
    getH5DrawingList    : `${domain}/crm/site/getH5DrawingList`, //  -get 获取图纸列表
    getDiscountList     : `${domain}/crm/site/getDiscountList` //  -get 获取优惠列表

}
export default site
