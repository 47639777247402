import axios from 'axios'
import { Notify } from 'vant'
// import store from '@/store'
// import { getToken } from '@/utils/auth'
import Vue from 'vue'
import { MD5 } from 'crypto-js'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    // timeout: 30000 // request timeout
})
const getSign = (params) => {
    const data = { timestamp: params.timestamp, openid: Vue.ls.get('openid') }

    const secret = '50&^T7qeUI&q^d7g'
    const keys = Object.keys(data).sort()
    const strArr = []
    keys.forEach(v => {
        // const key = typeof data[v] === 'object' ? secret : data[v]
        strArr.push(`${v}=${data[v]}`)
    })
    const str = strArr.join('&') + secret
    // console.log('str', str)
    return MD5(str).toString()
}

// request interceptor
service.interceptors.request.use(
    config => {
        const timestamp = Math.round(new Date().getTime() / 1000)
        // const timestamp = 1654069695
        // do something before request is sent

        if (Vue.ls.get('deviceId')) {
            // config.headers['Openid'] = Vue.ls.get('openid')
            config.headers['Device-Id'] = Vue.ls.get('deviceId')
            if (Vue.ls.get('userInfo')) {
                config.headers['Authorization'] = Vue.ls.get('userInfo').Authorization
                // config.headers['Company-Id'] = Vue.ls.get('userInfo').company_id
            }
        }
        if (config.method === 'post') {
            config.data = {
                timestamp,
                ...config.data
            }
            const sign = getSign(config.data)
            config.data.sign = sign
        } else if (config.method === 'get') {
            config.params = {
                timestamp,
                ...config.params
            }
            const sign = getSign(config.params)
            config.params.sign = sign
        }
        return config
    },
    error => {
        // do something with request error

        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data

        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 2000) {
            const errMessage = Vue.ls.get('err_message') || {}
            const message = res.message

            if (errMessage[res.message] !== 1) {
                Notify({
                    type    : 'danger',
                    message : message || '请求错误',
                    duration: 3000
                })
            }
            errMessage[res.message] = 1

            Vue.ls.set('err_message', errMessage, 5 * 1000)

            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code === 9999) {
                Vue.ls.clear()
                // to re-login
                // store.dispatch('user/resetToken').then(() => {
                location.href = '/#/login'
                // })
            }
            return res
        } else {
            return res
        }
    },
    error => {
        // console.log('err' + error) // for debug
        Notify({
            type    : 'warning',
            message : error.message === 'Network Error' ? '网络连错误' : error.message,
            duration: 3000
        })
        return Promise.reject(error)
    }
)
const request = {
    postUrlRequest({
        url,
        params,
        headers
    }) {
        return service({
            method          : 'post',
            url             : `${url}`,
            data            : params,
            transformRequest: [function(data) {
                let ret = ''
                for (const it in data) {
                    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
                }
                return ret
            }],
            headers: Object.assign({}, {
                'Content-Type': 'application/x-www-form-urlencoded'
            }, headers)
        })
    },
    postRequest({
        url,
        params,
        headers
    }) {
        return service({
            method : 'post',
            url    : `${url}`,
            data   : params,
            headers: Object.assign({}, {
                'Content-Type': 'application/json'
            }, headers)
        })
    },
    postRequestOthers({
        url,
        params,
        headers,
        others
    }) {
        return service({
            method : 'post',
            url    : `${url}`,
            data   : params,
            ...others,
            headers: Object.assign({}, {
                'Content-Type': 'application/json'
            }, headers)
        })
    },
    request(setting) {
        return service(setting)
    },
    putRequest({
        url,
        params,
        headers
    }) {
        return service({
            method: 'put',
            url   : `${url}`,
            data  : params,

            headers: Object.assign({}, {
                'Content-Type': 'application/json'
            }, headers)
        })
    },
    deleteRequest({
        url,
        params,
        param,
        headers
    }) {
        return service({
            method : 'delete',
            url    : `${url}`,
            data   : params,
            params : param,
            headers: Object.assign({}, {
                'Content-Type': 'application/json'
            }, headers)
        })
    },
    getRequest({
        url,
        params,
        headers
    }) {
        return service({
            method : 'get',
            url    : `${url}`,
            params : params,
            headers: Object.assign({}, {
                'Content-Type': 'application/x-www-form-urlencoded'
            }, headers)
        })
    }
}
export {
    request,
    service
}
