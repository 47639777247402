import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/* Router Modules */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [

    {
        path     : '/404',
        component: () => import('@/views/error-page/404'),
        hidden   : true
    },
    {
        name     : 'login',
        path     : '/login',
        component: () => import('@/views/login/index.vue'),
        meta     : {
            title: '登录'
        }
    },
    {
        name     : 'register',
        path     : '/register',
        component: () => import('@/views/register/index.vue'),
        meta     : {
            title: '注册'
        }
    },
    {
        path     : '',
        name     : 'top',
        component: Layout,
        meta     : {
            title: '首页'
        },
        redirect: '/index',
        children: [
            {
                name     : 'index',
                path     : 'index',
                component: () => import('@/views/index/index.vue'),
                meta     : {
                    title: '首页'
                }
            },
            // {
            //     name     : 'login',
            //     path     : 'login',
            //     component: () => import('@/views/login/index.vue'),
            //     meta     : {
            //         title: '登录'
            //     }
            // },
            // {
            //     name     : 'register',
            //     path     : 'register',
            //     component: () => import('@/views/register/index.vue'),
            //     meta     : {
            //         title: '注册'
            //     }
            // },
            {
                name     : 'construction',
                path     : 'construction',
                component: () => import('@/views/construction/index.vue'),
                meta     : {
                    title: '工地'
                }
            },
            {
                name     : 'msg',
                path     : 'msg',
                component: () => import('@/views/msg/index.vue'),
                meta     : {
                    title: '消息'
                }
            },
            {
                name     : 'mine',
                path     : 'mine',
                component: () => import('@/views/mine/index.vue'),
                meta     : {
                    title: '我的'
                }
            },
            {
                name     : 'valid',
                path     : 'valid',
                component: () => import('@/views/valid/index.vue'),
                meta     : {
                    title: '实名认证'
                }
            },
            {
                name     : 'success',
                path     : 'success',
                component: () => import('@/views/success/index.vue'),
                meta     : {
                    title: '注册成功'
                }
            },
            {
                name     : 'worker',
                path     : 'worker',
                component: () => import('@/views/mine/worker/index.vue'),
                meta     : {
                    title: '我的工人'
                }
            },
            {
                name     : 'foreman',
                path     : 'foreman',
                component: () => import('@/views/mine/foreman/index.vue'),
                meta     : {
                    title: '绑定工长'
                }
            },
            {
                name     : 'company',
                path     : 'company',
                component: () => import('@/views/mine/company/index.vue'),
                meta     : {
                    title: '绑定企业'
                }
            },
            {
                name     : 'settlement',
                path     : 'settlement',
                component: () => import('@/views/mine/settlement/index.vue'),
                meta     : {
                    title: '我的结算'
                }
            },
            {
                name     : 'settlement-detail',
                path     : 'settlement-detail',
                component: () => import('@/views/mine/settlement/detail/index.vue'),
                meta     : {
                    title: '查看详情'
                }
            }

        ]

    },
    {
        path     : '/building',
        name     : 'building',
        component: Layout,
        meta     : {
            title: '工地'
        },
        redirect: '/construction',
        children: [

            {
                name     : 'detail',
                path     : 'detail',
                component: () => import('@/views/building/detail/index.vue'),
                meta     : {
                    title: '工地详情'
                }
            },
            {
                name     : 'cost-detail',
                path     : 'cost-detail',
                component: () => import('@/views/building/cost-detail/index.vue'),
                meta     : {
                    title: '工费明细'
                }
            },
            {
                name     : 'auxiliary-confirm',
                path     : 'auxiliary-confirm',
                component: () => import('@/views/building/auxiliary-confirm/index.vue'),
                meta     : {
                    title: '辅材下单'
                }
            },
            {
                name     : 'auxiliary-detail',
                path     : 'auxiliary-detail',
                component: () => import('@/views/building/auxiliary-detail/index.vue'),
                meta     : {
                    title: '辅材确认'
                }
            },
            {
                name     : 'order-list',
                path     : 'order-list',
                component: () => import('@/views/building/order-list/index.vue'),
                meta     : {
                    title: '订单列表'
                }
            },
            {
                name     : 'order-detail',
                path     : 'order-detail',
                component: () => import('@/views/building/order-detail/index.vue'),
                meta     : {
                    title: '订单详情'
                }
            },
            {
                name     : 'order-confirm',
                path     : 'order-confirm',
                component: () => import('@/views/building/order-confirm/index.vue'),
                meta     : {
                    title: '订单确认'
                }
            },
            {
                name     : 'order-operation',
                path     : 'order-operation',
                component: () => import('@/views/building/order-operation/index.vue'),
                meta     : {
                    title: ''
                }
            },
            {
                name     : 'order-send',
                path     : 'order-send',
                component: () => import('@/views/building/order-send/index.vue'),
                meta     : {
                    title: '确认送货'
                }
            },
            {
                name     : 'order-install',
                path     : 'order-install',
                component: () => import('@/views/building/order-install/index.vue'),
                meta     : {
                    title: '确认安装'
                }
            },
            {
                name     : 'take-delivery',
                path     : 'take-delivery',
                component: () => import('@/views/building/take-delivery/index.vue'),
                meta     : {
                    title: '已取货'
                }
            },
            {
                name     : 'start',
                path     : 'start',
                component: () => import('@/views/building/start/index.vue'),
                meta     : {
                    title: '确认开工'
                }
            },
            {
                name     : 'progress',
                path     : 'progress',
                component: () => import('@/views/building/progress/index.vue'),
                meta     : {
                    title: '上报进度'
                }
            },
            {
                name     : 'progress-list',
                path     : 'progress-list',
                component: () => import('@/views/building/progress-list/index.vue'),
                meta     : {
                    title: '工地进度'
                }
            },
            {
                name     : 'contract',
                path     : 'contract',
                component: () => import('@/views/building/contract/index.vue'),
                meta     : {
                    title: '合同信息'
                }
            },
            {
                name     : 'drawing',
                path     : 'drawing',
                component: () => import('@/views/building/drawing/index.vue'),
                meta     : {
                    title: '图纸管理'
                }
            },
            {
                name     : 'discounts',
                path     : 'discounts',
                component: () => import('@/views/building/discounts/index.vue'),
                meta     : {
                    title: '优惠活动'
                }
            }

        ]

    },
    {
        path     : '/site',
        name     : 'site',
        component: Layout,
        meta     : {
            title: '工地'
        },
        redirect: '/rp-list',
        children: [

            {
                name     : 'rp-list',
                path     : 'rp-list',
                component: () => import('@/views/site/rp-list/index.vue'),
                meta     : {
                    title: '奖惩单'
                }
            },
            {
                name     : 'inspection',
                path     : 'inspection',
                component: () => import('@/views/site/inspection/index.vue'),
                meta     : {
                    title: '工地巡检'
                }
            },
            {
                name     : 'quality-inspection',
                path     : 'quality-inspection',
                component: () => import('@/views/site/quality-inspection/index.vue'),
                meta     : {
                    title: '工地质检'
                }
            },
            {
                name     : 'inspection-details',
                path     : 'inspection-details',
                component: () => import('@/views/site/inspection-details/index.vue'),
                meta     : {
                    title: '巡检详情'
                }
            },
            {
                name     : 'inspection-records',
                path     : 'inspection-records',
                component: () => import('@/views/site/inspection-records/index.vue'),
                meta     : {
                    title: '巡检记录'
                }
            },
            {
                name     : 'rectification',
                path     : 'rectification',
                component: () => import('@/views/site/rectification/index.vue'),
                meta     : {
                    title: '整改'
                }
            },
            {
                name     : 'camera',
                path     : 'camera',
                component: () => import('@/views/site/camera/index.vue'),
                meta     : {
                    title: '摄像头'
                }
            },
            {
                name     : 'acceptance-list',
                path     : 'acceptance-list',
                component: () => import('@/views/site/acceptance/list/index.vue'),
                meta     : {
                    title: '验收记录'
                }
            },
            {
                name     : 'acceptance-detail',
                path     : 'acceptance-detail',
                component: () => import('@/views/site/acceptance/detail/index.vue'),
                meta     : {
                    title: '验收详情'
                }
            },
            {
                name     : 'acceptance-audit',
                path     : 'acceptance-audit',
                component: () => import('@/views/site/acceptance/audit/index.vue'),
                meta     : {
                    title: '工地验收'
                }
            },
            {
                name     : 'acceptance-apply',
                path     : 'acceptance-apply',
                component: () => import('@/views/site/acceptance/apply/index.vue'),
                meta     : {
                    title: '申请验收'
                }
            },
            {
                name     : 'withdraw-list',
                path     : 'withdraw-list',
                component: () => import('@/views/site/withdraw/list/index.vue'),
                meta     : {
                    title: '请款记录'
                }
            },
            {
                name     : 'withdraw-add',
                path     : 'withdraw-add',
                component: () => import('@/views/site/withdraw/add/index.vue'),
                meta     : {
                    title: '新增请款'
                }
            },
            {
                name     : 'appoint',
                path     : 'appoint',
                component: () => import('@/views/site/appoint/index.vue'),
                meta     : {
                    title: '指派工人'
                }
            },
            {
                name     : 'statement-details',
                path     : 'statement-details',
                component: () => import('@/views/site/statement-details/index.vue'),
                meta     : {
                    title: '结算单详情'
                }
            }

        ]

    }

]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
    // 404 page must be placed at the end !!!
]

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes        : constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace

// push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject) }
    return originalPush.call(this, location).catch(err => err)
}

// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) { return originalReplace.call(this, location, onResolve, onReject) }
    return originalReplace.call(this, location).catch(err => err)
}
export default router
