<template>
    <div class="layout">
        <transition
            class="content"
            name="fade-transform"
            mode="out-in"
        >
            <router-view

                :key="key"
            />
        </transition>
        <tab-bar />
    </div>
</template>

<script>
import TabBar from '@/components/TabBar'
export default {
    name    : 'AppMain',
    computed: {

        key() {
            return this.$route.path
        }
    },
    components: {
        TabBar
    },
    props: {
        newMsg: {
            type   : Number,
            default: null
        }
    }
}
</script>
<style lang="scss" scoped>
.layout{
    margin-bottom: 50px;
}
.content{
    // height: 10vh;
    overflow: auto;
}
</style>

